import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18')
];

export const server_loads = [0];

export const dictionary = {
		"/": [~6],
		"/app": [11,[3]],
		"/app/logout": [12,[3]],
		"/app/search": [13,[3]],
		"/app/topics": [14,[3,4]],
		"/app/topics/[topicId]": [15,[3,4]],
		"/auth/login": [16,[5]],
		"/auth/signup": [~17,[5]],
		"/auth/verify-code": [18,[5]],
		"/(legal)/california-resident-notice-at-collection": [7,[2]],
		"/(legal)/privacy-policies": [8,[2]],
		"/(legal)/refund-policy": [9,[2]],
		"/(legal)/terms-and-conditions": [10,[2]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';